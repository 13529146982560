<template>
  <div class="mx-auto py-8 px-4" style="max-width: 400px" v-if="userInfo">
    <v-form>
      <v-card-title> Thông tin gói cước</v-card-title>
      <!-- <v-text-field outlined rounded disabled label="Email" v-model="package_name" /> -->
      <v-text-field
        v-model="package_name"
        outlined
        disabled
        rounded
        :rules="nameRules"
        label="Gói cước"
      />
      <v-text-field v-model="max_profile" outlined rounded disabled label="Số profile" />
      <v-text-field
        v-model="expriation_date"
        outlined
        rounded
        disabled
        label="Ngày hết hạn"
      />
      <v-btn
        depressed
        rounded
        color="primary"
        large
        width="100%"
        :loading="isLoading"
        :disabled="isLoading || invalid"
        @click="onSubmit()"
      >
        Gia hạn
      </v-btn>
    </v-form>
  </div>
</template>

<script setup lang="ts">
// import ACTIONS from "../../utils/actions";

const userInfo = ref(null);
const nameRules = [
  (v) => !!v || "Name is required",
  (v) => (v && v.length >= 5 && v.length <= 20) || "Tên phải có từ 5 đến 20 ký tự",
];
const phoneRules = [
  (v) => !!v || "Số điện thoại là bắt buộc",
  (v) => (v && /^\d+$/.test(v)) || "Số điện thoại chỉ được chứa số",
  (v) => (v && v.length === 10) || "Số điện thoại phải có 10 chữ số",
  (v) =>
    /^(08|09|03|07|05)/.test(v) ||
    "Số điện thoại phải bắt đầu bằng 08, 09, 03, 07 hoặc 05",
];

const isLoading = ref(false);
const package_name = ref("");
const max_profile = ref("");
const expriation_date = ref("");
const displayName = ref("");
const phone = ref("");
const invalid = ref(false);

const user = localStorage.getItem("userInfo");
userInfo.value = JSON.parse(user);
if (userInfo.value.package_id) {
  package_name.value = userInfo.value.package_id.package_name;
  max_profile.value = userInfo.value.package_id.permission.max_profile;
  expriation_date.value = userInfo.value.expriation_date;
}

const onSubmit = async () => {
  try {
    const params = {
      url: "https://facebook.com",
    };

  } catch (error) {

    Notify.error(JSON.stringify(error));
  }
};
</script>

<style></style>
