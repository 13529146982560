<script setup lang="ts">
import authApi from "@/api/auth.api";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const email = ref("");
const reset_key = ref("");
const password = ref("");
const re_password = ref("");
const token = ref("");

const router = useRouter();
const emailRules = [
  (v) => !!v || "Email is required",
  (v) =>
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Vui lòng nhập đúng email",
];

const error_message = ref("");

watch([re_password, password], (newValue, oldValue) => {
  if (password.value !== re_password.value) {
    error_message.value = "Password không khớp, vui lòng kiểm tra lại";
  }else{
    error_message.value = ""
  }
});

function goto_login() {
  router.push('/auth/login')
}

async function updatePassword() {
  try {
    const response = await authApi.updatePassword({
      email: email.value,
      password: password.value,
      reset_key: reset_key.value,
    });

    if (response.data.error == 0) {
      Notify.success(response.data.message)
      console.log(response.data);
      router.push('/auth/login')
    } else {
      error_message.value = response.data.message;
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));;
  }
}

</script>

<template>
    <div class="wrapper d-flex align-center justify-center pa-4">
    <v-card width="400" class="v-card pa-4 pt-7"
      ><div class="v-card-item justify-center">
        <div class="v-card-item__prepend">
              <v-icon icon="custom:vitify" size="1.5em" color="primary" class="mb-4" />
        </div>
        <div class="v-card-item__content">
          <!----><!---->
          <div class="v-card-title font-weight-semibold text-2xl text-uppercase">
            King Of Tool
          </div>
        </div>
        <!---->
      </div>
      <div class="v-card-text pt-2">
        <h5 class="text-h5 font-weight-semibold mb-1">Cập nhật mật khẩu! 👋🏻</h5>
      </div>
      <v-form>
        <v-text-field
          variant="outlined"
          :rules="emailRules"
          v-model="email"
          label="Email"
        ></v-text-field>
        <v-text-field
          variant="outlined"
          v-model="reset_key"
          label="Mã bảo mật"
        ></v-text-field>
        <v-text-field
          variant="outlined"
          v-model="password"
          label="Mật khẩu"
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="re_password"
          label="Nhập lại mật khẩu"
          type="password"
        ></v-text-field>
        <p class="ms-2" style="color: red; margin-bottom: 20px">{{ error_message }}</p>
        <v-btn
          block
          class="text-none mb-4"
          color="primary"
          size="x-large"
          variant="flat"
          @click="updatePassword"
        >
          Đổi mật khẩu
        </v-btn>
        <div class="v-col v-col-12 text-center text-base">
          <span>Bạn đã có mật khẩu hãy bấm</span
          ><a @click="goto_login" class="text-primary ms-2"> Đăng nhập </a>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>

</style>
