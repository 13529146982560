<script setup lang="ts">
;

import binanceApi from "@/api/binance.api";
const theme = useTheme();

const headers = [
  {
    title: "symbol",
    align: "start",
    key: "symbol",
    sortable: false,
  },
  { title: "status", key: "status" },
  { title: "Bollinger", key: "bollinger_status" },
  { title: "Option", key: "option" },
  { title: "Price", key: "lastPrice" },
  { title: "max", key: "max" },
  { title: "min", key: "min" },
  { title: "SD percent", key: "sd_percent" },
  { title: "lest_ranger", key: "lest_ranger" },
  { title: "specific", key: "specific" },
  { title: "Tp", key: "tp" },
  { title: "Sl", key: "sl" },
  { title: "Volume", key: "volume" },
  // { title: "Price TF", key: "price.toFix" },
  // { title: "quantity", key: "quantity.toFix" },
];

const symbols = ref([]);
const dialog = ref(false);
const form = ref({
  _id: "",
  symbol: "",
  min: 7,
  max: 5,
  tp: 1,
  sl: 1,

  dca: false,
  minDca: 10,
  maxDca: 10,
  dcaVolume: 200,

  specific: false,
});

let delete_item = ref({});
const dialog_delete = ref(false);
const dialog_check = ref(false);
const dialog_add = ref(false);
const notifi_message = ref("");
const search = ref("");
const color = ref("");
const symbol = ref("");
const symbolTrue = ref(0);
const selectedRows = ref(null);
const itemsPerPage = ref(50);
const total = ref(0);
const expriation_date = ref("");
const page = ref(1);
const loading = ref(true);

const filter = reactive({
  limit: itemsPerPage.value,
  page: page.value,
  keyword: "",
  sort: "status",
});
const sortType = ref([
  { name: "New Symbol", value: "new_symbol" },
  { name: "Status", value: "status" },
  { name: "sd_percent Thấp đến cao", value: "sd_percent_low_to_high" },
  { name: "sd_percent Cao đến thấp", value: "sd_percent_high_to_low" },
  { name: "lest_ranger Thấp đến cao", value: "slest_ranger_low_to_high" },
  { name: "lest_ranger Cao đến thấp", value: "lest_ranger_high_to_low" },
  { name: "Status", value: "status" },
  { name: "Price < 1", value: 1 },
  { name: "Price < 5", value: 5 },
  { name: "Price < 10", value: 10 },
  { name: "Price < 100", value: 100 },
  { name: "Price < 1000", value: 1000},
  { name: "Price < 10000", value: 10000 },
]);

watch(expriation_date, (newValue, oldValue) => {
  //form.value.expriation_date = expriation_date.toString()
  console.log(expriation_date.value);
});

watch([filter, itemsPerPage, page], (newValue, oldValue) => {
  filter.limit = itemsPerPage.value;
  filter.page = page.value;
  getAllSymbol(filter);
});

const editSymbol = (item) => {
  dialog.value = true;
  Object.assign(form, item);
};

const getAllSymbol = async (item) => {
  try {
    loading.value = true;
    const result = await binanceApi.getSymbol(item);

    if (result) {
      symbols.value = result.data;
      total.value = result.total;
      symbolTrue.value = result.symbolTrue;
      loading.value = false;
    }
  } catch (error) {
    console.log(error);
    loading.value = false;
    // Notify.error(JSON.stringify(error));
  }
};

function confirmDelete(item) {
  delete_item = item;
  dialog_delete.value = true;
}

const deleteSymbol = async (item) => {
  try {
    const result = await binanceApi.deleteSymbol({ _id: item._id });
    if (result.data.error == 0) {
      Notify.success("Xóa symbol thành công");
    } else {
      Notify.error(result.data.message);
    }
    getAllSymbol(filter);

    dialog_delete.value = false;
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

getAllSymbol(filter);

const loadItems = async (item) => {
  filter.keyword = search.value;

  await getAllSymbol(filter);
};

const onUpdateStatus = async (item) => {
  try {
    item.status = !item.status;
    if (item.bollinger_status) {
      item.bollinger_status = false;
    }
    const result = await binanceApi.updateSymbol(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
    } else {
      Notify.error(result.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const updateStatusAll = async (item) => {
  try {
    const data = {
      status: item
    }
    const result = await binanceApi.updateStatusAll(data);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
      getAllSymbol(filter);
    } else {
      Notify.error(result.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onUpdatebollingerStatus = async (item) => {
  try {
    item.bollinger_status = !item.bollinger_status;
    if (item.status) {
      item.status = false;
    }
    console.log("item: ", item);
    const result = await binanceApi.updateSymbol(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
    } else {
      Notify.error(result.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onUpdateSymbol = async (item) => {
  try {
    console.log(item);
    if (item.specific) {
      if (!item.volume || !item.min || !item.max || !item.tp || !item.sl) {
        Notify.error("Vui lòng điền đầy đủ tham số, volume, tp, sl, min, max");
        return;
      }
    }
    if (item.dca) {
      if (!item.dcaVolume || !item.minDca || !item.maxDca) {
        Notify.error("Vui lòng điền đầy đủ tham số, volume, minDca, maxDca");
        return;
      }
    }
    const result = await binanceApi.updateSymbol(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
    } else {
      Notify.error(result.data.message);
    }

    dialog_add.value = false;
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onEdit = async (item) => {
  item.dcaVolume = item.dcaVolume ? item.dcaVolume : item.volume;
  item.minDca = item.minDca ? item.minDca : item.min;
  item.maxDca = item.maxDca ? item.maxDca : item.max;
  form.value = item;
  expriation_date.value = item.expriation_date
    ? Date(item.expriation_date).toString()
    : new Date();
  dialog_add.value = true;
};
const onAdd = async () => {
  dialog_add.value = true;
  form.value.symbol = "";
  form.value._id = "";
};
function rowClicked(row) {
  toggleSelection(row._id);
}
function toggleSelection(keyID) {
  if (selectedRows.value === keyID) {
    //selectedRows.value = null
  } else {
    selectedRows.value = keyID;
  }
}
const onDelete = async (item) => {};
</script>

<template>
  <v-row style="">
    <v-col cols="12" sm="12" md="12">
      <v-card-title> Trade : {{ symbolTrue }} </v-card-title>
    </v-col>
  </v-row>
  <v-row class="ml-5">
    <v-col cols="4" sm="4" md="4">
      <v-btn color="green" @click="updateStatusAll('all_bollinger')"> Bollinger </v-btn>
    </v-col>
    <v-col cols="4" sm="4" md="4">
      <v-btn color="blue" @click="updateStatusAll('all_nadaraya')"> nadaraya </v-btn>
    </v-col>
    <v-col cols="4" sm="4" md="4">
      <v-btn color="red" @click="updateStatusAll('all_off')"> OFF ALL </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6" sm="6" md="6">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-inner-icon="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
          label="Tìm symbol"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </v-col>
    <v-col cols="6" sm="6" md="3\6">
      <v-card-title>
        <v-select :items="sortType"
        v-model="filter.sort"
        item-value="value"
        item-title="name"
        > </v-select>
      </v-card-title>
    </v-col>
  </v-row>

  <v-data-table-server
    v-if="symbols"
    v-model:items-per-page="itemsPerPage"
    v-model:page="page"
    :items-length="total"
    :headers="headers"
    :items="symbols"
    fixed-header
    height="68vh"
    :loading="loading"
    :search="search"
    @update:options="loadItems"
    class="elevation-1 ma-4"
  >
    <template v-slot:item="{ item, index }">
      <tr
        :style="`color:${
          selectedRows === item._id ? theme.themes.value.light.colors.primary : ''
        }`"
        @click="rowClicked(item)"
      >
        <!-- <td><v-checkbox></v-checkbox></td> -->
        <td style="width: 120px; "> <a style="color:rgb(0, 215, 253)" :target="'_blank'" :href="`https://www.binance.com/vi/futures/${item.symbol}`"> {{ item.symbol }} </a></td>
        <td>
          <v-switch
            v-model="item.status"
            color="green"
            hide-details
            @click="onUpdateStatus(item)"
          ></v-switch>
        </td>
        <td>
          <v-switch
            v-model="item.bollinger_status"
            color="green"
            hide-details
            @click="onUpdatebollingerStatus(item)"
          ></v-switch>
        </td>
        <td>
          <div style="display: inline-flex">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="onEdit(item)"
                />
              </template>
              <span> Chỉnh sửa symbol</span>
            </v-tooltip>
            <!-- <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="confirmDelete(item)"
                />
              </template>
              <span> Xoá symbol</span>
            </v-tooltip> -->
          </div>
        </td>
        <td>{{ item.lastPrice }}</td>
        <td>{{ item.max }}</td>
        <td>{{ item.min }}</td>
        <td>{{ item.sd_percent }}</td>
        <td>{{ item.lest_ranger }}</td>
        <td>{{ item.specific }}</td>
        <td>{{ item.tp }}</td>
        <td>{{ item.sl }}</td>
        <td>{{ item.volume }}</td>
        <!--
        <td>{{ item.price.toFix }}</td>
        <td>{{ item.quantity.toFix }}</td> -->
      </tr>
    </template>
  </v-data-table-server>

  <v-dialog v-model="dialog_delete" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline">Confirm Delete</v-card-title>
      <v-card-text>Bạn muốn xóa symbol {{ delete_item.email }} ?</v-card-text>
      <v-card-actions>
        <v-btn @click="dialog_delete = false">Hủy bỏ</v-btn>
        <v-btn v-model="delete_item" color="error" @click="deleteSymbol(delete_item)"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog_add" persistent max-width="100vh">
    <v-card class="pa-4">
      <v-card-title v-if="form._id" class="headline">Chỉnh sửa symbol</v-card-title>
      <v-row>
        <v-col cols="12">
          <v-switch
            v-model="form.specific"
            color="green"
            hide-details
            label="Specific"
          ></v-switch>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.symbol"
            label="symbol*"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.volume"
            label="Volume*"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.min" label="Phần trăm biên độ giá nhỏ nhất" variant="outlined"></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.max" label="Phần trăm biên độ giá Cao nhất" variant="outlined"></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.tp" label="Tp" variant="outlined"></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.sl" label="Sl" variant="outlined"></v-text-field>
        </v-col>

      </v-row>
      <v-card-title v-if="form._id" class="headline">Cài đặt bollinger</v-card-title>
      <v-row class="mt-5">
        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.bollinger_tp" label="bollinger_tp" variant="outlined"></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.bollinger_sl" label="bollinger_sl" variant="outlined"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-switch v-model="form.dca" color="green" hide-details label="DCA"></v-switch>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <v-text-field
            v-model="form.minDca"
            label="Min % DCA"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <v-text-field
            v-model="form.maxDca"
            label="Max % DCA"
            variant="outlined"
          ></v-text-field>
        </v-col>

        <v-col cols="3" sm="3" md="3">
          <v-text-field
            v-model="form.dcaVolume"
            label="DCA Volume"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center justify-center">
        <v-card-text :style="`color:${color}`"> {{ notifi_message }}</v-card-text>
        <v-card-actions>
          <v-btn v-if="form._id" color="green" @click="onUpdateSymbol(form)"
            >Cập nhật</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn color="red" @click="dialog_add = false">Hủy bỏ</v-btn>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
// .wrapper {
//   position: relative;
//   margin-top: 150px;
//   text-align: center;
// }
</style>
