<script setup lang="ts">
import authApi from "@/api/auth.api";
import userApi from "@/api/users.api";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const username = ref(localStorage.getItem("username"));
const password = ref(localStorage.getItem("password"));
const access_token = ref("");
const error_message = ref("");
const remember = ref(true);
const router = useRouter();
const isPasswordVisible = ref(false);

watch(remember, (newValue, oldValue) => {});

function goto_register() {
  router.push("/auth/register");
}
function goto_forgotPassword() {
  router.push("/auth/forgotPassword");
}

async function sigin() {
  try {
    const check_login = await authApi.login({
      email: username.value,
      password: password.value,
    });
    if (check_login.data.error == 0) {
      localStorage.setItem("uid", check_login.data.data.uid);
      localStorage.setItem("access_token", check_login.data.data.access_token);
      localStorage.setItem("refresh_token", check_login.data.data.refresh_token);
      if (remember.value == true) {
        localStorage.setItem("username", username.value);
        localStorage.setItem("password", password.value);
      }
      await userApi.getInfo();
      router.push("/dashboard");
    } else {
      error_message.value = check_login.data.message;
      Notify.error(check_login.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
    Notify.error("Có lỗi khi đăng nhập");
  }
}
;
</script>

<template>
  <div class="wrapper d-flex align-center justify-center pa-4">
    <v-card width="400" class="v-card pa-4 pt-7"
      ><div class="v-card-item justify-center">
        <div class="v-card-item__prepend">
          <div class="d-flex">
            <div>
              <v-icon icon="custom:vitify" size="1.5em" color="primary" class="mb-4" />
            </div>
          </div>
        </div>
        <div class="v-card-item__content">
          <!----><!---->
          <div class="v-card-title font-weight-semibold text-2xl text-uppercase">
            Đăng nhập
          </div>
        </div>
        <!---->
      </div>

      <v-form>
        <v-text-field
          variant="outlined"
          v-model="username"
          label="Email hoặc số điện thoại"
          @keypress.enter="sigin"
        ></v-text-field>
        <v-text-field
          variant="outlined"
          v-model="password"
          label="Password"
          :type="isPasswordVisible ? 'text' : 'password'"
          :append-inner-icon="isPasswordVisible ? 'M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z' : 'M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z'"
          @click:append-inner="isPasswordVisible = !isPasswordVisible"
          @keypress.enter="sigin"
        ></v-text-field>
        <p class="text-primary ms-2" style="color: red">{{ error_message }}</p>
        <div class="d-flex align-center justify-space-between flex-wrap mt-1 mb-4">
          <div
            class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-checkbox"
          >
            <v-checkbox v-model="remember" label="Ghi nhớ đăng nhập"></v-checkbox>
            <div></div>
          </div>
        </div>

        <v-btn
          block
          class="text-none mb-4"
          color="primary"
          size="x-large"
          variant="flat"
          @click="sigin"
        >
          Đăng nhập
        </v-btn>
        <div class="v-col v-col-12 text-center text-base">
          <span><a @click="goto_forgotPassword"> Bạn quên mật khẩu? </a></span
          ><a @click="goto_register" class="text-primary ms-2"> Đăng ký tài khoản </a>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  // margin-top: 100px;
  text-align: center;
}
</style>
