<script setup lang="ts">
import authApi from "@/api/auth.api";
import userApi from "@/api/users.api";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const username = ref(localStorage.getItem("username"));
const password = ref(localStorage.getItem("password"));
const access_token = ref("");
const error_message = ref("");
const remember = ref(true);
const router = useRouter();
const isPasswordVisible = ref(false);

watch(remember, (newValue, oldValue) => {});

function goto_register() {
  router.push("/auth/register");
}
function goto_forgotPassword() {
  router.push("/auth/forgotPassword");
}

async function sigin() {
  try {
    const check_login = await authApi.login({
      email: username.value,
      password: password.value,
    });
    if (check_login.data.error == 0) {
      localStorage.setItem("uid", check_login.data.data.uid);
      localStorage.setItem("access_token", check_login.data.data.access_token);
      localStorage.setItem("refresh_token", check_login.data.data.refresh_token);
      if (remember.value == true) {
        localStorage.setItem("username", username.value);
        localStorage.setItem("password", password.value);
      }
      await userApi.getInfo();
      router.push("/dashboard");
    } else {
      error_message.value = check_login.data.message;
      Notify.error(check_login.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
    Notify.error("Có lỗi khi đăng nhập");
  }
}
definePage({
  meta: {
    icon: "mdi-folder-account",
    title: "Login",
    drawerIndex: 0,
  },
});
</script>

<template>
  <div class="wrapper d-flex align-center justify-center pa-4">
    <v-card width="400" class="v-card pa-4 pt-7"
      ><div class="v-card-item justify-center">
        <div class="v-card-item__prepend">
          <div class="d-flex">
            <div>
              <v-icon icon="custom:vitify" size="1.5em" color="primary" class="mb-4" />
            </div>
          </div>
        </div>
        <div class="v-card-item__content">
          <!----><!---->
          <div class="v-card-title font-weight-semibold text-2xl text-uppercase">
            Đăng nhập
          </div>
        </div>
        <!---->
      </div>

      <v-form>
        <v-text-field
          variant="outlined"
          v-model="username"
          label="Email hoặc số điện thoại"
          @keypress.enter="sigin"
        ></v-text-field>
        <v-text-field
          variant="outlined"
          v-model="password"
          label="Password"
          :type="isPasswordVisible ? 'text' : 'password'"
          :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye-outline'"
          @click:append-inner="isPasswordVisible = !isPasswordVisible"
          @keypress.enter="sigin"
        ></v-text-field>
        <p class="text-primary ms-2" style="color: red">{{ error_message }}</p>
        <div class="d-flex align-center justify-space-between flex-wrap mt-1 mb-4">
          <div
            class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-checkbox"
          >
            <v-checkbox v-model="remember" label="Ghi nhớ đăng nhập"></v-checkbox>
            <div></div>
          </div>
        </div>

        <v-btn
          block
          class="text-none mb-4"
          color="primary"
          size="x-large"
          variant="flat"
          @click="sigin"
        >
          Đăng nhập
        </v-btn>
        <div class="v-col v-col-12 text-center text-base">
          <span><a @click="goto_forgotPassword"> Bạn quên mật khẩu? </a></span
          ><a @click="goto_register" class="text-primary ms-2"> Đăng ký tài khoản </a>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  // margin-top: 100px;
  text-align: center;
}
</style>
