import { api } from './index';
import cookieUtil from '@/utils/cookie.util';

export default Object.freeze({
  async getInfo() {
    const uid = localStorage.getItem('uid');
    const res = await api.get('/api/company/info', {
      params: { _id: uid },
    });
    if(res.data.error === 0){
      const item = res.data.data;
      // localStorage.setItem("userInfo", JSON.stringify(item));
      return item;
    }else{
      Notify.error(res.data.message)
    }

  },
  getCompany(data) {
    return api.get('/api/all-company', {
      params: data,
    });
  },
  timKiemHoaDon(data) {
    return api.get('/api/company/tim-hoa-don', {
      params: data,
    });
  },
  getCompanyById(data) {
    return api.get('/api/company-by-id', {
      params: data,
    });
  },

  deleteCompany(data) {
    return api.delete('/api/company',{
      params: data,
    });
  },
  updateCompany(data) {
    return api.patch('/api/company/', data);
  },
  addCompany(data) {
    return api.post('/api/company/', data);
  }

});
