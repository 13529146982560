import _page_0 from '/app/src/pages/index.vue'
import _definePage_default_1 from '/app/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _page_3 from '/app/src/pages/[...all].vue'
import _page_4 from '/app/src/pages/auth.vue'
import _page_5 from '/app/src/pages/auth/forgotPassword.vue'
import _definePage_default_6 from '/app/src/pages/auth/forgotPassword.vue?definePage&vue'
import _page_7 from '/app/src/pages/auth/login.vue'
import _definePage_default_8 from '/app/src/pages/auth/login.vue?definePage&vue'
import _page_9 from '/app/src/pages/auth/register.vue'
import _definePage_default_10 from '/app/src/pages/auth/register.vue?definePage&vue'
import _page_11 from '/app/src/pages/auth/updatePassword.vue'
import _definePage_default_12 from '/app/src/pages/auth/updatePassword.vue?definePage&vue'
import _definePage_default_13 from '/app/src/pages/auth.vue?definePage&vue'
import _page_14 from '/app/src/pages/company.vue'
import _page_15 from '/app/src/pages/company/companyDetail.vue'
import _definePage_default_16 from '/app/src/pages/company/companyDetail.vue?definePage&vue'
import _page_17 from '/app/src/pages/company/companyManager.vue'
import _definePage_default_18 from '/app/src/pages/company/companyManager.vue?definePage&vue'
import _definePage_default_19 from '/app/src/pages/company.vue?definePage&vue'
import _page_20 from '/app/src/pages/dashboard.vue'
import _page_21 from '/app/src/pages/dashboard/dashboard.vue'
import _definePage_default_22 from '/app/src/pages/dashboard/dashboard.vue?definePage&vue'
import _definePage_default_23 from '/app/src/pages/dashboard.vue?definePage&vue'
import _page_24 from '/app/src/pages/facebook.vue'
import _page_25 from '/app/src/pages/facebook/menu1-setting.vue'
import _definePage_default_26 from '/app/src/pages/facebook/menu1-setting.vue?definePage&vue'
import _page_27 from '/app/src/pages/facebook/menu1-symbol.vue'
import _definePage_default_28 from '/app/src/pages/facebook/menu1-symbol.vue?definePage&vue'
import _definePage_default_29 from '/app/src/pages/facebook.vue?definePage&vue'
import _page_30 from '/app/src/pages/settings.vue'
import _page_31 from '/app/src/pages/settings/billingHistory.vue'
import _page_32 from '/app/src/pages/settings/changePassword.vue'
import _page_33 from '/app/src/pages/settings/couponCode.vue'
import _page_34 from '/app/src/pages/settings/packageInfo.vue'
import _page_35 from '/app/src/pages/settings/settingsNavigation.vue'
import _page_36 from '/app/src/pages/settings/userInterface.vue'
import _page_37 from '/app/src/pages/settings/userSetting.vue'
import _definePage_default_38 from '/app/src/pages/settings.vue?definePage&vue'
import _page_39 from '/app/src/pages/tiktok.vue'
import _page_40 from '/app/src/pages/tiktok/setting.vue'
import _definePage_default_41 from '/app/src/pages/tiktok/setting.vue?definePage&vue'
import _page_42 from '/app/src/pages/tiktok/tang_tuong_tac.vue'
import _definePage_default_43 from '/app/src/pages/tiktok/tang_tuong_tac.vue?definePage&vue'
import _definePage_default_44 from '/app/src/pages/tiktok.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: _page_0,
    /* no children */
  },
  _definePage_default_1
  ),
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: _page_3,
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/auth',
    name: '/auth',
    component: _page_4,
    children: [
  _mergeRouteRecord(
      {
        path: 'forgotPassword',
        name: '/auth/forgotPassword',
        component: _page_5,
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: 'login',
        name: '/auth/login',
        component: _page_7,
        /* no children */
      },
  _definePage_default_8
  ),
  _mergeRouteRecord(
      {
        path: 'register',
        name: '/auth/register',
        component: _page_9,
        /* no children */
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: 'updatePassword',
        name: '/auth/updatePassword',
        component: _page_11,
        /* no children */
      },
  _definePage_default_12
  )
    ],
  },
  _definePage_default_13
  ),
  _mergeRouteRecord(
  {
    path: '/company',
    name: '/company',
    component: _page_14,
    children: [
  _mergeRouteRecord(
      {
        path: 'companyDetail',
        name: '/company/companyDetail',
        component: _page_15,
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'companyManager',
        name: '/company/companyManager',
        component: _page_17,
        /* no children */
      },
  _definePage_default_18
  )
    ],
  },
  _definePage_default_19
  ),
  _mergeRouteRecord(
  {
    path: '/dashboard',
    name: '/dashboard',
    component: _page_20,
    children: [
  _mergeRouteRecord(
      {
        path: 'dashboard',
        name: '/dashboard/dashboard',
        component: _page_21,
        /* no children */
      },
  _definePage_default_22
  )
    ],
  },
  _definePage_default_23
  ),
  _mergeRouteRecord(
  {
    path: '/facebook',
    name: '/facebook',
    component: _page_24,
    children: [
  _mergeRouteRecord(
      {
        path: 'menu1-setting',
        name: '/facebook/menu1-setting',
        component: _page_25,
        /* no children */
      },
  _definePage_default_26
  ),
  _mergeRouteRecord(
      {
        path: 'menu1-symbol',
        name: '/facebook/menu1-symbol',
        component: _page_27,
        /* no children */
      },
  _definePage_default_28
  )
    ],
  },
  _definePage_default_29
  ),
  _mergeRouteRecord(
  {
    path: '/settings',
    name: '/settings',
    component: _page_30,
    children: [
      {
        path: 'billingHistory',
        name: '/settings/billingHistory',
        component: _page_31,
        /* no children */
      },
      {
        path: 'changePassword',
        name: '/settings/changePassword',
        component: _page_32,
        /* no children */
      },
      {
        path: 'couponCode',
        name: '/settings/couponCode',
        component: _page_33,
        /* no children */
      },
      {
        path: 'packageInfo',
        name: '/settings/packageInfo',
        component: _page_34,
        /* no children */
      },
      {
        path: 'settingsNavigation',
        name: '/settings/settingsNavigation',
        component: _page_35,
        /* no children */
      },
      {
        path: 'userInterface',
        name: '/settings/userInterface',
        component: _page_36,
        /* no children */
      },
      {
        path: 'userSetting',
        name: '/settings/userSetting',
        component: _page_37,
        /* no children */
      }
    ],
  },
  _definePage_default_38
  ),
  _mergeRouteRecord(
  {
    path: '/tiktok',
    name: '/tiktok',
    component: _page_39,
    children: [
  _mergeRouteRecord(
      {
        path: 'setting',
        name: '/tiktok/setting',
        component: _page_40,
        /* no children */
      },
  _definePage_default_41
  ),
  _mergeRouteRecord(
      {
        path: 'tang_tuong_tac',
        name: '/tiktok/tang_tuong_tac',
        component: _page_42,
        /* no children */
      },
  _definePage_default_43
  )
    ],
  },
  _definePage_default_44
  )
]
