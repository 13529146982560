<script setup lang="ts">
definePage({
  meta: {
    title: 'Tiktok',
    icon: 'mdi-alpha-t-box-outline',
    drawerIndex: 6,
    breadcrumb: 'disabled',
  },
})
</script>
<template>
  <RouterWrapper />
</template>
