<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();

// definePage({
//   meta: {
//     icon: "M9 3V18H12V3H9M12 5L16 18L19 17L15 4L12 5M5 5V18H8V5H5M3 19V21H21V19H3Z",
//     title: "Quản lý công ty",
//     drawerIndex: 2,
//   },
// });

router.push("/company/companyManager");
</script>

<!-- <template>
  <Company/>
</template> -->
<template>
  <RouterWrapper />
</template>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  margin-top: 10px;
  text-align: center;
}
</style>
