<template>
  <div class="mx-auto py-8 px-4" style="max-width: 400px" v-if="userInfo">
    <v-form>
      <v-card-title> Thay đổi thông tin tài khoản</v-card-title>
      <v-text-field outlined rounded disabled label="Email" v-model="email" />
      <v-text-field
        v-model="displayName"
        outlined
        rounded
        :rules="nameRules"
        label="Họ tên"
        :counter="32"
      />
      <v-text-field
        v-model="phone"
        outlined
        rounded
        :rules="phoneRules"
        label="Số diện thoại"
        :counter="10"
      />
      <v-btn
        depressed
        rounded
        color="primary"
        large
        width="100%"
        :loading="isLoading"
        :disabled="isLoading || invalid"
        @click="onSubmit()"
      >
        Cập nhật thông tin tài khoản
      </v-btn>
    </v-form>
  </div>
</template>

<script setup lang="ts">
import userApi from "@/api/users.api";

const userInfo = ref(null);
const nameRules = [
  (v) => !!v || "Name is required",
  (v) => (v && v.length >= 5 && v.length <= 20) || "Tên phải có từ 5 đến 20 ký tự",
];
const phoneRules = [
  (v) => !!v || "Số điện thoại là bắt buộc",
  (v) => (v && /^\d+$/.test(v)) || "Số điện thoại chỉ được chứa số",
  (v) => (v && v.length === 10) || "Số điện thoại phải có 10 chữ số",
  (v) =>
    /^(08|09|03|07|05)/.test(v) ||
    "Số điện thoại phải bắt đầu bằng 08, 09, 03, 07 hoặc 05",
];

const isLoading = ref(false);
const email = ref("");
const displayName = ref("");
const phone = ref("");
const invalid = ref(false);

const user = localStorage.getItem("userInfo");
userInfo.value = user?JSON.parse(user):{};

email.value = userInfo.value.email;
displayName.value = userInfo.value.displayName;
phone.value = userInfo.value.phone;
const onSubmit = async () => {
  try {
    isLoading.value = true;
    const result = await userApi.updateInfo({ displayName: displayName.value, phone: phone.value });
    isLoading.value = false;
    if (result.data.error === 0) {
      const message = "Cập nhật thông tin thành công";
      Notify.success(message)
      await userApi.getInfo()
    } else {
      const message = "Có lỗi xảy ra!";
      Notify.error(message)
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};
</script>

<style></style>
