/* eslint-disable prettier/prettier */
import axios from "axios";
import CONSTANTS from "@/utils/constants";
import cookieUtil from "@/utils/cookie.util";

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

export const api = axios.create({ baseURL:CONSTANTS.BASE_API });

const uid = localStorage.getItem("uid");

export const authInstance = axios.create({
  baseURL: CONSTANTS.BASE_API,
});

api.interceptors.request.use(
  (request) => {
    request.headers["authorization"] = localStorage.getItem("access_token");
    request.headers["destination"] = "super-change";
    request.headers["uid"] = uid;
    return request;
  },
  (error) => {
    console.log(error)
    return {
      error: 3,
      message: "Có lỗi gì đó xảy ra"
  }
  }
);

api.interceptors.response.use(
  (response) => {

    // if (response?.data?.error == 0) {
    //   const newSessionId = response.headers["access_token"];
    //   console.log("newSessionId", newSessionId)
    //   if (newSessionId) {
    //     localStorage.setItem("access_token", newSessionId);
    //   }

    // }


    return response;
  },
  (error) => {
    console.log(error)
    return {
      error: 3,
      message: "Có lỗi gì đó xảy ra"
  }
  }
);

authInstance.interceptors.request.use((request) => {
  request.headers["authorization"] = localStorage.getItem("access_token");

  return request;
});

authInstance.interceptors.response.use((response) => {
  // if (response?.data?.code === 1) {
  //   return Promise.reject({ message: response.data.message });
  // }

  // const newSessionId = response.headers["access_token"];
  // if (newSessionId) {
  //   localStorage.setItem("access_token", newSessionId);
  // }

  return response;
});
