<template>
  <div class="mx-auto py-8 px-4" style="max-width: 400px">
    <v-card-title>Thay đổi giao diện</v-card-title>
    <v-select
      outlined
      rounded
      width="100%"
      :items="fontFamilies"
      label="Font chữ"
      item-value="value"
      item-title="name"
      v-model="selectedFontFamily"
      @change="onSelectFontFamily"
    />
    <v-select
    outlined
      rounded
      :items="fontSizes"
      label="Cỡ chữ"
      item-value="value"
      item-title="name"
      v-model="selectedFontSize"
      @change="onSelectFontSize"
    />

  </div>
</template>

<script setup lang="ts">

const selectedFontFamily = ref("");
const selectedFontSize = ref("");

selectedFontSize.value = localStorage.getItem("font-size");
selectedFontFamily.value = localStorage.getItem("font-family");

watch([selectedFontFamily, selectedFontSize], (newValue, oldValue) => {
onSelectFontSize()
onSelectFontFamily()
});


const fontSizes = [
  {
    name: "Nhỏ",
    value: "16",
  },
  {
    name: "Vừa",
    value: "18",
  },
  {
    name: "Lớn",
    value: "20",
  },
];
const fontFamilies = [
  {
    name: "Roboto",
    value: "Roboto",
  },
  {
    name: "Quicksand",
    value: "Quicksand",
  },
  {
    name: "Open Sans",
    value: "Open Sans",
  },
];

function onSelectFontSize () {
  const css = `font-size: ${selectedFontSize.value}px !important`;
  document.querySelector("html").style.cssText = css;
  localStorage.setItem("font-size", selectedFontSize.value);
};
function onSelectFontFamily (){
  try {
    const css = `font-family: '${selectedFontFamily.value}', sans-serif !important`;
    document.querySelector("#app").style.cssText = css;
    localStorage.setItem("font-family", selectedFontFamily.value);
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};
</script>

<style></style>
