<script setup lang="ts">
import groupList from "./dashboard/dashboard.vue"
definePage({
  meta: {
    icon: "mdi-bookshelf",
    title: "Dashboard",
     drawerIndex: 1,
  },
});
</script>

<template>
  <groupList/>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  margin-top: 10px;
  text-align: center;
}
</style>
