<script setup lang="ts">
import companyApi from "@/api/company.api";
const theme = useTheme();

const headers = [
  {
    title: "Ký hiệu mẫu số",
    align: "start",
    key: "name",
    sortable: false,
  },
  { title: "Ký hiệu hóa đơn", key: "phone" },
  { title: "Số hóa đơn", key: "password" },
  { title: "Ngày lập", key: "tax" },
  { title: "Tổng tiền chưa thuế", key: "created" },
  { title: "Tổng tiền thuế", key: "option" },
  { title: "Tổng tiền chiết khấu thương mại", key: "option" },
  { title: "Tổng tiền phí", key: "option" },
  { title: "Tổng tiền thanh toán", key: "option" },
  { title: "Đơn vị tiền tệ", key: "option" },
  { title: "Trạng thái hóa đơn", key: "option" },
];

const companies = ref([]);
const dialog = ref(false);
const form = ref({
  _id: "",
  email: "",
  phone: "",
  package_id: {},
  expriation_date: "",
  displayName: "",
});

let delete_item = ref({});
const dialog_delete = ref(false);
const dialog_check = ref(false);
const dialog_add = ref(false);
const notifi_message = ref("");
const search = ref("");
const color = ref("");
const company = ref("");
const companyType = ref("");
const selectedRows = ref(null);
const itemsPerPage = ref(10);
const total = ref(0);
const expriation_date = ref("");
const page = ref(1);
const loading = ref(true);

const filter = reactive({
  limit: itemsPerPage.value,
  page: page.value,
  keyword: "",
  mst: "",
  cccd: "",
  tthd: "",
  kqkt: "",
  hdun: false,
  nlhd: "",
  khmshd: "",
  shd: "",
  khhd: "",
});

watch(expriation_date, (newValue, oldValue) => {
  //form.value.expriation_date = expriation_date.toString()
  console.log(expriation_date.value);
});

watch([filter, itemsPerPage, page], (newValue, oldValue) => {
  filter.limit = itemsPerPage.value;
  filter.page = page.value;
  // getAllProfile(filter);
});

const editCompany = (item) => {
  dialog.value = true;
  Object.assign(form, item);
};

const timKiemHoaDon = async (item) => {
  try {
    loading.value = true;
    const result = await companyApi.getCompany(item);
    if (result.data.error == 0) {
      companies.value = result.data.data;
      total.value = result.data.total;
      loading.value = false;
    }
  } catch (error) {
    loading.value = false;
    Notify.error(JSON.stringify(error));
  }
};

function confirmDelete(item) {
  delete_item = item;
  dialog_delete.value = true;
}

const deleteCompany = async (item) => {
  try {
    const result = await companyApi.deleteCompany({ _id: item._id });
    if (result.data.error == 0) {
      Notify.success("Xóa company thành công");
    } else {
      Notify.error(result.data.message);
    }
    timKiemHoaDon(filter);

    dialog_delete.value = false;
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

timKiemHoaDon(filter);

const loadItems = async (item) => {
  filter.keyword = search.value;
  await timKiemHoaDon(filter);
};

const onUpdate = async (item) => {
  try {
    dialog_add.value = false;
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};
const onAddCompany = async (item) => {
  try {
    dialog_add.value = false;
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onEdit = async (item) => {
  form.value = item;
  dialog_add.value = true;
};
const onSearch = async () => {
  dialog_add.value = true;
  form.value.company = "";
  form.value._id = "";
};
function rowClicked(row) {
  toggleSelection(row._id);
}
function toggleSelection(keyID) {
  if (selectedRows.value === keyID) {
    //selectedRows.value = null
  } else {
    selectedRows.value = keyID;
  }
}
const onDelete = async (item) => {};

definePage({
  meta: {
    // icon: 'mdi-folder-account',
    title: "Chi tiết công ty",
    // drawerIndex: 1,
  },
});
</script>

<template>
  <v-row style="">
    <v-col cols="3" sm="3" md="3">
      <v-card-title> Chi tiết công ty </v-card-title>
    </v-col>
  </v-row>
  <v-row class="ma-5">
    <v-col cols="6">
      <v-text-field label="MST người mua" v-model="filter.mst" variant="outlined">
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field label="CCCD người mua" v-model="filter.cccd" variant="outlined">
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-select label="Trạng thái hóa đơn" v-model="filter.cccd" variant="outlined">
      </v-select>
    </v-col>
    <v-col cols="6">
      <v-text-field label="Ngày lập hóa đơn" v-model="filter.mst" variant="outlined">
      </v-text-field>
      <!-- <v-date-picker
      multiple
      ></v-date-picker> -->
    </v-col>
    <v-col cols="6">
      <v-select label="Kết quả kiểm tra" v-model="filter.kqkt" variant="outlined">
      </v-select>
    </v-col>
    <v-col cols="6">
      <v-text-field label="Ký hiệu mẫu số hóa đơn" v-model="filter.khmshd" variant="outlined">
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field label="Số hóa đơn" v-model="filter.shd" variant="outlined">
      </v-text-field>
    </v-col>

    <v-col cols="6">
      <v-text-field label="Ký hiệu hóa đơn" v-model="filter.khhd" variant="outlined">
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-checkbox label="Hóa đơn ủy nhiệm"></v-checkbox>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="2" sm="2" md="2" class="mt-4 ml-4">
      <v-btn color="success" style="margin-top: 10px" @click="onSearch"> Tìm kiếm </v-btn>
    </v-col>

  </v-row>

  <v-data-table-server
    v-if="companies"
    v-model:items-per-page="itemsPerPage"
    v-model:page="page"
    :items-length="total"
    :headers="headers"
    :items="companies"
    fixed-header
    height="68vh"
    :loading="loading"
    :search="search"
    @update:options="loadItems"
    class="elevation-1 ma-4"
  >
    <template v-slot:item="{ item }">
      <tr
        :style="`color:${
          selectedRows === item._id ? theme.themes.value.light.colors.primary : ''
        }`"
        @click="rowClicked(item)"
      >
        <!-- <td><v-checkbox></v-checkbox></td> -->
        <td style="width: 200px">{{ item.name }}</td>
        <td>{{ item.username }}</td>
        <td>{{ item.password }}</td>
        <td>{{ item.tax }}</td>
        <td>{{ item.created }}</td>
        <td>
          <div style="display: inline-flex">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="mdi-pencil"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="onEdit(item)"
                />
              </template>
              <span> Chỉnh sửa</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="mdi-delete"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="confirmDelete(item)"
                />
              </template>
              <span> Xoá</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table-server>

  <v-dialog v-model="dialog_delete" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline">Confirm Delete</v-card-title>
      <v-card-text>Bạn muốn xóa company {{ delete_item.email }} ?</v-card-text>
      <v-card-actions>
        <v-btn @click="dialog_delete = false">Hủy bỏ</v-btn>
        <v-btn v-model="delete_item" color="error" @click="deleteCompany(delete_item)"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog_add" persistent max-width="100vh">
    <v-card class="pa-4">
      <v-card-title v-if="form._id" class="headline">Chỉnh sửa công ty</v-card-title>
      <v-card-title v-else class="headline">Thêm công ty</v-card-title>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="form.name"
            label="Tên công ty*"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.username"
            label="Tài khoản*"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.password"
            label="Mật khẩu*"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.email"
            label="Email"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.phone"
            label="Điện thoại"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.tax"
            label="Mã số thuế"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.address"
            label="Địa chỉ"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center justify-center">
        <v-card-text :style="`color:${color}`"> {{ notifi_message }}</v-card-text>
        <v-card-actions>
          <v-btn v-if="form._id" color="green" @click="onUpdate(form)">Cập nhật</v-btn>
          <v-btn v-else color="green" @click="onAddCompany(form)">Thêm công ty</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="red" @click="dialog_add = false">Hủy bỏ</v-btn>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
// .wrapper {
//   position: relative;
//   margin-top: 150px;
//   text-align: center;
// }
</style>
