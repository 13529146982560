<script setup lang="ts">
import settingsNavigation from "./settings/settingsNavigation.vue";
import { useRouter } from "vue-router";
import vuetify from "@/plugins/vuetify";

const isMobile = ref(vuetify.display.mobile);

const router = useRouter();

const isShowChildComponent = ref(false);

router.push("/settings/userSetting");

definePage({
  meta: {
    title: "Cài đặt",
    icon: "mdi-cog",
  },
});
</script>
<template>
  <div>
    <v-row no-gutters v-if="!isMobile">
      <v-col cols="4">
        <settingsNavigation />
      </v-col>
      <v-col
        cols="8"
        class="divider-left"
        style="height: calc(100vh - 48px); overflow-y: auto"
      >
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </v-col>
    </v-row>
    <div v-if="isMobile">
      <settingsNavigation />

      <router-view />
    </div>
  </div>
</template>
