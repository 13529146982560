import { createRouter, createWebHistory } from 'vue-router/auto'
import { setupLayouts } from 'virtual:meta-layouts'
import authApi from "@/api/auth.api";

const BASE_TITLE = "King of Tool ADMIN";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  extendRoutes: (routes) => setupLayouts(routes),
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title + ' | ' + BASE_TITLE;
  const result_check = await authApi.check();
  let isAuthenticated = false

  if (result_check.data.error === 0) {
    isAuthenticated = true
  }

  if ((to.path === '/auth/login' || to.path === '/auth/register')  && isAuthenticated) {
    next({ path: '/profiles' })
  }

  if (to.path !== '/auth/forgotPassword' && to.path !== '/auth/updatePassword' && to.path !== '/auth/login' && to.path !== '/auth/register' && !isAuthenticated) {
    next({ path: '/auth/login' })
  } else {
    next()
  }

});

export default router
