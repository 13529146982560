<script setup lang="ts">
definePage({
  meta: {
    title: 'Facebook',
    icon: 'mdi-facebook',
    drawerIndex: 5,
    breadcrumb: 'disabled',
  },
})
</script>
<template>
  <RouterWrapper />
</template>
