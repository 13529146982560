<script setup lang="ts">
definePage({
  meta: {
    title: "Setting",
    icon: "mdi-view-list",
  },
});

import binanceApi from "@/api/binance.api";
const theme = useTheme();
const headers = [
  {
    title: "Name",
    align: "start",
    key: "name",
    sortable: false,
  },
  { title: "status", key: "status" },
  { title: "Type", key: "run_type" },
  { title: "Option", key: "option" },
  { title: "Cycle", key: "cycle" },
  { title: "max", key: "max" },
  { title: "min", key: "min" },
  { title: "Tp", key: "tp" },
  { title: "Sl", key: "sl" },
  { title: "Volume", key: "volume" },
  // { title: "Price TF", key: "price.toFix" },
  // { title: "quantity", key: "quantity.toFix" },
];

const runType = ref([
  { name: "Nadaraya", value: "nadaraya" },
  { name: "Bollinger", value: "bollinger" },
]);

const time_cycle = ref([
  { name: "1 Phút", value: 1 },
  { name: "5 Phút", value: 5 },
  { name: "15 Phút", value: 15 },
  { name: "30 Phút", value: 30 },
  { name: "60 Phút", value: 60 },
]);

const form = ref({});
const settings = ref([]);
const symbolSelected = ref([]);
const search = ref("");
const dialog_edit = ref(false);
const notifi_message = ref("");
const color = ref("");
const total = ref(0);
const loading = ref(true);
const itemsPerPage = ref(50);
const page = ref(1);
const selectedRows = ref(null);
const filter = reactive({
  limit: itemsPerPage.value,
  page: page.value,
  keyword: "",
  sort: "status",
});

const getSetting = async (filter) => {
  try {
    loading.value = true;
    const result = await binanceApi.getSetting(filter);
    if (result.data.error == 0) {
      settings.value = result.data.data;
      loading.value = false;
    }
  } catch (error) {
    loading.value = false;
    console.log(error);
  }
};
const symbols = ref([]);
const getAllSymbol = async (item) => {
  try {
    loading.value = true;
    const result = await binanceApi.getSymbol(item);

    if (result) {
      symbols.value = result.data;
      total.value = result.total;
      loading.value = false;
    }
  } catch (error) {
    console.log(error);
    loading.value = false;
    // Notify.error(JSON.stringify(error));
  }
};
getAllSymbol({
  limit: 0,
  page: 0,
  keyword: "",
  sort: "symbol",
});

getSetting();

const onUpdate = async (item) => {
  try {
    // for (let key in item) {
    //   if (key !== "uid" && key !== "status" && key !== "bollinger_status" && !item[key]) {
    //     Notify.error("Vui lòng cập nhật đủ các trường");
    //     console.log(key);
    //     return;
    //   }
    // }
    const result = await binanceApi.updateSetting(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
    } else {
      Notify.error(result.data.message);
    }

    dialog_edit.value = false;
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onUpdateStatus = async (item) => {
  try {
    item.status = !item.status;
    if (item.bollinger_status) {
      item.bollinger_status = false;
    }
    const result = await binanceApi.updateSetting(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
    } else {
      Notify.error(result.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onUpdatebollingerStatus = async (item) => {
  try {
    item.bollinger_status = !item.bollinger_status;
    if (item.status) {
      item.status = false;
    }
    console.log("item: ", item);
    const result = await binanceApi.updateSetting(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
    } else {
      Notify.error(result.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onEdit = async (item) => {
  item.dcaVolume = item.dcaVolume ? item.dcaVolume : item.volume;
  item.minDca = item.minDca ? item.minDca : item.min;
  item.maxDca = item.maxDca ? item.maxDca : item.max;
  form.value = item;
  dialog_edit.value = true;
};

const loadItems = async (item) => {
  // filter.keyword = search.value;

  await getSetting();
};

function rowClicked(row) {
  toggleSelection(row._id);
}
function toggleSelection(keyID) {
  if (selectedRows.value === keyID) {
    //selectedRows.value = null
  } else {
    selectedRows.value = keyID;
  }
}
</script>

<template>
  <v-row>
    <v-col cols="3" sm="3" md="3">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          label="Tìm kiếm"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </v-col>
  </v-row>
  <v-data-table-server
    v-if="settings"
    v-model:items-per-page="itemsPerPage"
    v-model:page="page"
    :items-length="total"
    :headers="headers"
    :items="settings"
    fixed-header
    height="68vh"
    :loading="loading"
    :search="search"
    @update:options="loadItems"
    class="elevation-1 ma-4"
  >
    <template v-slot:item="{ item, index }">
      <tr
        :style="`color:${
          selectedRows === item._id ? theme.themes.value.light.colors.primary : ''
        }`"
        @click="rowClicked(item)"
      >
        <!-- <td><v-checkbox></v-checkbox></td> -->
        <td style="width: 100px">{{ item.name }}</td>
        <td>
          <v-switch
            v-model="item.status"
            color="green"
            hide-details
            @click="onUpdateStatus(item)"
          ></v-switch>
        </td>
        <td>{{ item.run_type }}</td>

        <td>
          <div style="display: inline-flex">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="mdi-pencil"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="onEdit(item)"
                />
              </template>
              <span> Chỉnh sửa setting</span>
            </v-tooltip>
            <!-- <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="mdi-delete"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="confirmDelete(item)"
                />
              </template>
              <span> Xoá symbol</span>
            </v-tooltip> -->
          </div>
        </td>
        <td>{{ item.cycle }}</td>

        <td>{{ item.max }}</td>
        <td>{{ item.min }}</td>
        <td>{{ item.tp }}</td>
        <td>{{ item.sl }}</td>
        <td>{{ item.volume }}</td>
        <!--
        <td>{{ item.price.toFix }}</td>
        <td>{{ item.quantity.toFix }}</td> -->
      </tr>
    </template>
  </v-data-table-server>

  <v-dialog v-model="dialog_edit" persistent max-width="100vh">
    <v-row style="">
      <v-col cols="3" sm="3" md="3">
        <v-card-title> Binance Setting </v-card-title>
      </v-col>
    </v-row>
    <v-card class="pa-4">
      <v-card-title v-if="form._id" class="headline">Chỉnh sửa setting</v-card-title>

      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-switch
            v-model="form.status"
            color="green"
            hide-details
            label="Status"
          ></v-switch>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-switch
            v-model="form.trend"
            color="green"
            hide-details
            label="Trend"
          ></v-switch>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-switch
            v-model="form.only_long"
            color="green"
            hide-details
            label="Long"
          ></v-switch>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-switch
            v-model="form.only_short"
            color="green"
            hide-details
            label="Short"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-combobox
            v-model="form.symbols"
            :items="symbols"
            label="Chọn symbols"
            item-title="symbol"
            item-value="symbol"
            multiple
            chips
            closable-chips
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-select
            :items="runType"
            v-model="form.run_type"
            item-value="value"
            item-title="name"
            label="Phong cách trade"
          >
          </v-select>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-select
            :items="time_cycle"
            v-model="form.cycle"
            item-value="value"
            item-title="name"
            label="Cycle"
          >
          </v-select>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.max_symbol_trade"
            label="Max symbol trade"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.volume"
            label="Volume"
            variant="outlined"
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-combobox
            v-model="form.min"
            label="Phần trăm long price"
            multiple
            chips
            closable-chips
          ></v-combobox>

        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-combobox
            v-model="form.max"
            label="Phần trăm short price"
            multiple
            chips
            closable-chips
          ></v-combobox>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.tp" label="Tp" variant="outlined"></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field v-model="form.sl" label="Sl" variant="outlined"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.min_sd"
            label="Hệ số sd cộng thêm mặc định"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.max_sd"
            label="Hệ số sd cộng thêm khi sd < tp"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.time_check"
            label="bollinger time check"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.max_position"
            label="Max position"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.trend_sd"
            label="+- % last price trend"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.name"
            label="Name"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.sl_percent"
            label="% set sl dương"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.max_position"
            label="Max position"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center justify-center">
        <v-card-text :style="`color:${color}`"> {{ notifi_message }}</v-card-text>
        <v-card-actions>
          <v-btn color="green" @click="onUpdate(form)">Cập nhật</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="red" @click="dialog_edit = false">Hủy bỏ</v-btn>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
// .wrapper {
//   position: relative;
//   margin-top: 150px;
//   text-align: center;
// }
</style>
