<template>
  <div class="mx-auto py-8 px-4" style="max-width: 400px">
    <v-card-title>Mã gia hạn</v-card-title>
    <v-text-field v-model.trim="code" outlined rounded label="Nhập mã" />
    <v-btn
      depressed
      rounded
      color="primary"
      large
      width="100%"
      :loading="isLoading"
      :disabled="isLoading || invalid"
      @click="onSubmit"
    >
      Xác nhận
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import userApi from "@/api/users.api";

const code = ref("");
const invalid = ref(false);
const isLoading = ref(false);

const onSubmit = async () => {
  try {
    const form = {
      code: code.value,
    };

    isLoading.value = true;
    const result = await userApi.useCouponCode(form);

    isLoading.value = false;
    if (result.data.status === "success") {

      const message = "Cập nhật mã khuyến mãi thành công";
      Notify.success(message)
      return;
    } else {

      const message = result.data.message;
      Notify.error(message)
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};
</script>

<style></style>
