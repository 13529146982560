import { api } from './index';
import cookieUtil from '@/utils/cookie.util';

export default Object.freeze({
  async getInfo() {
    const uid = localStorage.getItem('uid');
    const res = await api.get('/api/tang_tuong_tac/info', {
      params: { _id: uid },
    });
    if(res.data.error === 0){
      const item = res.data.data;
      // localStorage.setItem("userInfo", JSON.stringify(item));
      return item;
    }else{
      Notify.error(res.data.message)
    }

  },
  getTang_tuong_tac(data) {
    return api.get('/api/all-tang_tuong_tac', {
      params: data,
    });
  },
  timKiemHoaDon(data) {
    return api.get('/api/tang_tuong_tac/tim-hoa-don', {
      params: data,
    });
  },
  getTang_tuong_tacById(data) {
    return api.get('/api/tang_tuong_tac-by-id', {
      params: data,
    });
  },

  deleteTang_tuong_tac(data) {
    return api.delete('/api/tang_tuong_tac',{
      params: data,
    });
  },
  updateTang_tuong_tac(data) {
    return api.patch('/api/tang_tuong_tac/', data);
  },
  addTang_tuong_tac(data) {
    return api.post('/api/tang_tuong_tac/', data);
  }

});
