<template>
  <div class="mx-auto py-8 px-4" style="max-width: 400px">
  <v-form>
    <v-card-title> Đổi mật khẩu mới</v-card-title>
    <v-text-field
      v-model="oldPassword"
      outlined
      rounded
      :rules="oldPasswordRules"
      type="password"
      label="Nhập mật hiện tại"

    />
    <v-text-field
      v-model="newPassword"
      outlined
      rounded
      type="password"
      label="Nhập mật khẩu mới"
      :rules="newPasswordRules"
      :counter="32"

    />
    <v-text-field
      v-model.trim="confirmNewPassword"
      outlined
      rounded
      type="password"
      label="Nhập lại mật khẩu mới"
      :rules="confirmNewPasswordRules"
      :counter="32"

    />
    <v-btn
      depressed
      rounded
      color="primary"
      large
      width="100%"
      :loading="isLoading"
      :disabled="isLoading"
      @click="onSubmit"
    >
      Đổi mật khẩu
    </v-btn>
  </v-form>
  </div>
</template>

<script setup lang="ts">
import userApi from "@/api/users.api";

const isLoading = ref(false);
const oldPassword = ref("");
const newPassword = ref("");
const confirmNewPassword = ref("");

const oldPasswordRules = [
  (v) => !!v || 'Mật khẩu mới là bắt buộc',
];

const newPasswordRules = [
  (v) => !!v || 'Mật khẩu mới là bắt buộc',
  (v) => (v && v.length >= 5 && v.length <= 20) || 'Mật khẩu mới phải có từ 5 đến 20 ký tự'
];

const confirmNewPasswordRules = [
  (v) => !!v || 'Xác nhận mật khẩu là bắt buộc',
  (v) => (v && v === newPassword.value) || 'Xác nhận mật khẩu phải giống với mật khẩu mới'
];


const onSubmit = async () => {
  try {
    isLoading.value = true;
    const form = {
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
      confirmNewPassword: confirmNewPassword.value,
    };
    const result = await userApi.changePassword(form );
    isLoading.value = false;
    if (result.data.error === 0) {
      const message = "Đổi mật khẩu thành công";
      Notify.success(message)
      return
    }else{
      const message =result.data.message;
      Notify.error(message)
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};
</script>

<style></style>
