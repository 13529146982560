/* eslint-disable prettier/prettier */
import { api, authInstance } from "./index";


const authApi = {

  check: ()  => {
    return authInstance.get("/auth-check");
  },

  login: (queryParams: {})  => {
    return api.post("/login/", {
      data: queryParams,
    });
  },

  updatePassword: (queryParams: {})  => {
    return api.post("/updatePassword", {
      data: queryParams,
    });
  },

  forgotPassword: (queryParams: {})  => {
    return api.post("/forgotPassword", {
      data: queryParams,
    });
  },

  register: (queryParams: {})  => {
    return api.post("/register", {
      data: queryParams,
    });
  },
}


export default authApi;
