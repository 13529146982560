<script setup lang="ts">
import authApi from "@/api/auth.api";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const email = ref("");
const phone = ref("");
const password = ref("");
const re_password = ref("");
const token = ref("");
const isPasswordVisible = ref(false);
const router = useRouter();
const emailRules = [
  (v) => !!v || "Email is required",
  (v) =>
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Vui lòng nhập đúng email",
];

const error_message = ref("");

watch([re_password, password], (newValue, oldValue) => {
  if (password.value !== re_password.value) {
    error_message.value = "Password không khớp, vui lòng kiểm tra lại";
  }else{
    error_message.value = ""
  }
});

function goto_login() {
  router.push('/auth/login')
}

async function register() {
  try {
    const response = await authApi.register({
      email: email.value,
      password: password.value,
      phone: phone.value,
    });

    if (response.data.error == 0) {
      Notify.success(" Đăng ký thành công")
      console.log(response.data);
      localStorage.setItem("uid", response.data.data.uid);
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      router.push("/profiles");
    } else {
      error_message.value = response.data.message;
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));;
  }
}

</script>

<template>
    <div class="wrapper d-flex align-center justify-center pa-4">
    <v-card width="400" class="v-card pa-4 pt-7"
      ><div class="v-card-item justify-center">
        <div class="v-card-item__prepend">
              <v-icon icon="custom:vitify" size="1.5em" color="primary" class="mb-4" />
        </div>
        <div class="v-card-item__content">
          <!----><!---->
          <div class="v-card-title font-weight-semibold text-2xl text-uppercase">
            King Of Tool
          </div>
        </div>
        <!---->
      </div>
      <div class="v-card-text pt-2">
        <h5 class="text-h5 font-weight-semibold mb-1">Welcome to King Of Tool! 👋🏻</h5>
        <p class="mb-0">Đăng nhập và trải nghiệm</p>
      </div>
      <v-form>
        <v-text-field
          variant="outlined"
          :rules="emailRules"
          v-model="email"
          label="Email"
        ></v-text-field>
        <v-text-field
          variant="outlined"
          v-model="phone"
          label="Số điện thoại"
        ></v-text-field>
        <v-text-field
          variant="outlined"
          v-model="password"
          label="Password"
          :type="isPasswordVisible ? 'text' : 'password'"
          :append-inner-icon="isPasswordVisible ? 'M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z' : 'M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z'"
          @click:append-inner="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>
        <v-text-field
          v-model="re_password"
          label="Nhập lại password"
          :type="isPasswordVisible ? 'text' : 'password'"
          :append-inner-icon="isPasswordVisible ? 'M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z' : 'M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z'"
          @click:append-inner="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>
        <p class="ms-2" style="color: red; margin-bottom: 20px">{{ error_message }}</p>
        <v-btn
          block
          class="text-none mb-4"
          color="primary"
          size="x-large"
          variant="flat"
          @click="register"
        >
          Đăng ký
        </v-btn>
        <div class="v-col v-col-12 text-center text-base">
          <span>Bạn đã có tài khoản hãy bấm</span
          ><a @click="goto_login" class="text-primary ms-2"> Đăng nhập </a>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>

</style>
