import { api } from './index';
import cookieUtil from '@/utils/cookie.util';

export default Object.freeze({
  async getInfo() {
    const uid = localStorage.getItem('uid');
    const res = await api.get('/api/users/info', {
      params: { _id: uid },
    });
    if(res.data.error === 0){
      const item = res.data.data;
      localStorage.setItem("userInfo", JSON.stringify(item));
      return item;
    }else{
      Notify.error(res.data.message)
    }

  },
  getExtUser(data) {
    return api.get('/api/users/ext', {
      params: data,
    });
  },
  getSuperChangeUser(data) {
    return api.get('/api/users/super-change',{
      params: data,
    });
  },
  deleteUser(data) {
    return api.delete('/api/users/admin/delete',{
      params: data,
    });
  },
  adminUpdateUser(data) {
    return api.patch('/api/users/admin/update', data);
  },
  resetPass(data) {
    return api.patch('/api/users/admin/reset-assword', data);
  },

});
