<script setup lang="ts">
import authApi from "../../api/auth.api"
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const email = ref(localStorage.getItem("email"));
const password = ref(localStorage.getItem("password"));
const access_token = ref("");
const error_message = ref("");
const remember = ref(true);
const router = useRouter();
watch(remember, (newValue, oldValue) => {

});



function goto_register() {
  router.push('/auth/register')
}

function goto_login() {
  router.push('/auth/login')
}

async function forgotPassword() {
  // try {
  const data = await authApi.forgotPassword({
    email: email.value,
    password: password.value,
  });
  if (data.data.error == 0) {
     Notify.success(data.data.message)

    router.push("/auth/updatePassword");
  } else {
    Notify.error(data.data.message)
  }

}

</script>

<template>
  <div class="wrapper d-flex align-center justify-center pa-4">
    <v-card
      width="400"
      class="v-card pa-4 pt-7"
      ><div class="v-card-item justify-center">
        <div class="v-card-item__prepend">
          <div class="d-flex">
            <div>
              <v-icon icon="custom:vitify" size="1.5em" color="primary" class="mb-4" />
            </div>
          </div>
        </div>
        <div class="v-card-item__content">
          <!----><!---->
          <div class="v-card-title font-weight-semibold text-2xl text-uppercase">
            King Of Tool
          </div>
        </div>
        <!---->
      </div>
      <div class="v-card-text pt-2">
        <h5 class="text-h5 font-weight-semibold mb-1">Quên mật khẩu! 👋🏻</h5>
        <p class="mb-0">Vui lòng nhập email để lấy lại mật khẩu</p>
      </div>
      <v-form>
        <v-text-field
          variant="outlined"
          v-model="email"
          label="Nhập email"
        ></v-text-field>
        <p class="text-primary ms-2" style="color: red">{{ error_message }}</p>

        <v-btn
          block
          class="text-none mb-4"
          color="primary"
          size="x-large"
          variant="flat"
          @click="forgotPassword"
        >
          Lấy lại mật khẩu
        </v-btn>
        <div class="v-col v-col-12 text-center text-base">
          <span><a  @click="goto_login" class="text-primary ms-2"> Đănh nhập? </a></span
          ><a @click="goto_register" class="text-primary ms-2"> Đăng ký tài khoản </a>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  // top: max(50vh - 300px, 0px);
  text-align: center;
}
</style>
