import { api } from './index';
export default Object.freeze({
  async getSymbol(params) {
    const uid = localStorage.getItem('uid');
    const res = await api.get('/binance/symbol', {
      params: params,
    });
    if(res.data.error === 0){
      const item = res.data;
      return item;
    }else{
      Notify.error(res.data.message)
    }

  },
  getSetting(data) {
    return api.get('/binance/setting', {
      params: data,
    });
  },

  updateSetting(data) {
    return api.patch('/binance/setting', data);
  },

  updateSymbol(data) {
    return api.patch('/binance/symbol', data);
  },
  updateStatusAll(data) {
    return api.patch('/binance/symbol/updateStatusAll', data);
  },

});
