<script setup lang="ts">
import tang_tuong_tacApi from "@/api/tang_tuong_tac.api";
import { useRouter } from "vue-router";

;

const router = useRouter();
const theme = useTheme();

const headers = [
  {
    title: "Tên",
    align: "start",
    key: "name",
    sortable: false,
  },
  // { title: "Url", key: "url" },
  { title: "Loại tương tác", key: "action" },
  { title: "Tổng tương tác", key: "max" },
  { title: "Đã tương tác", key: "count" },
  { title: "Thời gian chờ", key: "delay" },
  { title: "Ngày bắt đầu", key: "start_time" },
  { title: "Trạng thái", key: "status" },
  { title: "Tùy chọn", key: "option" },
];

const companies = ref([]);
const dialog = ref(false);
const form = ref({
  _id: "",
  email: "",
  phone: "",
  package_id: {},
  expriation_date: "",
  displayName: "",
});

let delete_item = ref({});
const dialog_delete = ref(false);
const dialog_check = ref(false);
const dialog_add = ref(false);
const notifi_message = ref("");
const search = ref("");
const color = ref("");
const tang_tuong_tac = ref("");
const tang_tuong_tacType = ref("");
const selectedRows = ref(null);
const itemsPerPage = ref(10);
const total = ref(0);
const expriation_date = ref("");
const page = ref(1);
const loading = ref(true);

const filter = reactive({
  limit: itemsPerPage.value,
  page: page.value,
  keyword: "",
  type: "tiktok"
});

const actions = ref([
  { name: "Thả tim", value: "heart" },
  { name: "Comment", value: "comment" },
  { name: "Share", value: "share" },
  { name: "View", value: "view" },
  { name: "Follow", value: "follow" },
]);

const max = ref([
  { name: "10", value: 10 },
  { name: "50", value: 50 },
  { name: "100", value: 100 },
  { name: "500", value: 500 },
  { name: "1000", value: 1000 },
]);
const delayArray = ref([
  { name: "10", value: 10 },
  { name: "60", value: 60 },
  { name: "120", value: 120 },
  { name: "300", value: 300 },
  { name: "600", value: 600 },
]);

watch(expriation_date, (newValue, oldValue) => {
  //form.value.expriation_date = expriation_date.toString()
  console.log(expriation_date.value);
});

watch([filter, itemsPerPage, page], (newValue, oldValue) => {
  filter.limit = itemsPerPage.value;
  filter.page = page.value;
  // getAllProfile(filter);
});

const editTang_tuong_tac = (item) => {
  dialog.value = true;
  Object.assign(form, item);
};

const getAllTang_tuong_tac = async (item) => {
  try {
    loading.value = true;
    const result = await tang_tuong_tacApi.getTang_tuong_tac(item);
    if (result.data.error == 0) {
      companies.value = result.data.data;
      total.value = result.data.total;
      loading.value = false;
    }
  } catch (error) {
    loading.value = false;
    Notify.error(JSON.stringify(error));
  }
};

function confirmDelete(item) {
  delete_item = item;
  dialog_delete.value = true;
}

const deleteTang_tuong_tac = async (item) => {
  try {
    const result = await tang_tuong_tacApi.deleteTang_tuong_tac({ _id: item._id });
    if (result.data.error == 0) {
      Notify.success("Xóa tang_tuong_tac thành công");
    } else {
      Notify.error(result.data.message);
    }
    getAllTang_tuong_tac(filter);

    dialog_delete.value = false;
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

getAllTang_tuong_tac(filter);

const loadItems = async (item) => {
  filter.keyword = search.value;
  await getAllTang_tuong_tac(filter);
};

const onUpdate = async (item) => {
  try {
    form.value.type = "tiktok";
    const result = await tang_tuong_tacApi.updateTang_tuong_tac(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
      dialog_add.value = false;
    } else {
      Notify.error(result.data.message);
    }
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};
const onAddTang_tuong_tac = async (item) => {
  try {
    form.value.type = "tiktok";
    const result = await tang_tuong_tacApi.addTang_tuong_tac(item);
    if (result.data.error == 0) {
      Notify.success("Cập nhật thành công");
      dialog_add.value = false;
    } else {
      Notify.error(result.data.message);
    }
    getAllTang_tuong_tac(filter);
  } catch (error) {
    Notify.error(JSON.stringify(error));
  }
};

const onEdit = async (item) => {
  form.value = item;
  dialog_add.value = true;
};
const onView = async (url) => {
  console.log("url ", url);
  window.open(url);
};
const onAdd = async () => {
  dialog_add.value = true;
  form.value._id = "";
};
function rowClicked(row) {
  toggleSelection(row._id);
}
function toggleSelection(keyID) {
  if (selectedRows.value === keyID) {
    //selectedRows.value = null
  } else {
    selectedRows.value = keyID;
  }
}
const onDelete = async (item) => {};
</script>

<template>
  <v-row style="">
    <v-col cols="3" sm="3" md="3">
      <v-card-title> Danh sách đã lên lịch </v-card-title>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="2" sm="2" md="2" class="mt-4 ml-4">
      <v-btn color="success" style="margin-top: 10px" @click="onAdd">
        Thêm cài đặt
      </v-btn>
    </v-col>
    <v-col cols="6" sm="6" md="6">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-inner-icon="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
          label="Tìm kiếm"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </v-col>
  </v-row>

  <v-data-table-server
    v-if="companies"
    v-model:items-per-page="itemsPerPage"
    v-model:page="page"
    :items-length="total"
    :headers="headers"
    :items="companies"
    fixed-header
    height="68vh"
    :loading="loading"
    :search="search"
    @update:options="loadItems"
    class="elevation-1 ma-4"
  >
    <template v-slot:item="{ item }">
      <tr
        :style="`color:${
          selectedRows === item._id ? theme.themes.value.light.colors.primary : ''
        }`"
        @click="rowClicked(item)"
      >
        <!-- <td><v-checkbox></v-checkbox></td> -->
        <td style="width: 200px">{{ item.name }}</td>
        <!-- <td><a :href="item.url" target="_blank">Xem</a></td> -->
        <td>{{ item.action === "heart" ? "Thả tim" : item.action }}</td>
        <td>{{ item.max }}</td>
        <td>{{ item.count }}</td>
        <td>{{ item.delay }}</td>
        <td>{{ item.start_time }}</td>
        <td>{{ item.status }}</td>
        <td>
          <div style="display: inline-flex">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="onView(item.url)"
                />
              </template>
              <span> Xem</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="onEdit(item)"
                />
              </template>
              <span> Chỉnh sửa</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
                  size="1.5em"
                  :color="'primary'"
                  class="mb-4"
                  @click="confirmDelete(item)"
                />
              </template>
              <span> Xoá</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table-server>

  <v-dialog v-model="dialog_delete" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline">Confirm Delete</v-card-title>
      <v-card-text>Bạn muốn xóa tang_tuong_tac {{ delete_item.email }} ?</v-card-text>
      <v-card-actions>
        <v-btn @click="dialog_delete = false">Hủy bỏ</v-btn>
        <v-btn
          v-model="delete_item"
          color="error"
          @click="deleteTang_tuong_tac(delete_item)"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog_add" persistent max-width="100vh">
    <v-card class="pa-4">
      <v-card-title v-if="form._id" class="headline">Chỉnh sửa cài đặt</v-card-title>
      <v-card-title v-else class="headline">Thêm cài đặt</v-card-title>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="form.name"
            label="Tên*"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="form.url"
            label="Link video*"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-combobox
            :items="actions"
            v-model="form.action"
            variant="outlined"
            clearable
            closable-chips
            item-value="value"
            item-title="name"
            label="Loại tương tác"
            :return-object="false"
          >
          </v-combobox>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          Tổng số tương tác (<span style="color: red">*</span>)
          <v-tooltip location="top" max-width="300" style="" color="red">
            <template v-slot:activator="{ props }">
              <v-icon icon="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" v-bind="props"> </v-icon>
            </template>
            <span> Tổng số tương tác bạn muốn cài đặt cho bài viết này.</span>
          </v-tooltip>

          <v-combobox
            :items="max"
            v-model="form.max"
            variant="outlined"
            clearable
            closable-chips
            item-value="value"
            item-title="name"
            label="Tổng số tương tác"
            class="mt-2"
            :return-object="false"
          />
        </v-col>
        <v-col cols="6" sm="6" md="6">
          Thời gian chờ (Đơn vị: giây) (<span style="color: red">*</span>)
          <v-tooltip location="top" max-width="300" style="" color="red">
            <template v-slot:activator="{ props }">
              <v-icon icon="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" v-bind="props"> </v-icon>
            </template>
            <span>
              Thời gian chờ giữa các tương tác. đơn vị là giây (Ví dụ: 5, 10, 60,
              180).</span
            >
          </v-tooltip>

          <v-combobox
            :items="delayArray"
            v-model="form.delay"
            variant="outlined"
            clearable
            closable-chips
            item-value="value"
            item-title="name"
            label="Thời gian chờ"
            class="mt-2"
            :return-object="false"
          />
        </v-col>
        <v-row no-gutters style="width: 300px">
          <v-col cols="6" sm="6" md="6" style="padding-right: 25px">
            <v-list-item>
              Thời gian bắt đầu (<span style="color: red">*</span>)
              <v-tooltip location="top" max-width="300" style="" color="red">
                <template v-slot:activator="{ props }">
                  <v-icon icon="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" v-bind="props"> </v-icon>
                </template>
                <span> Thời gian bắt đầu.</span>
              </v-tooltip>
            </v-list-item>
            <VueDatePicker
              class="ml-4"
              v-model="form.start_time"
              :enable-time-picker="true"
            />
          </v-col>
          <!-- <v-col cols="6" sm="6" md="6" style="padding-left: 10px; padding-right: 10px;">
            <v-list-item>
              Thời gian kết thúc (<span style="color: red">*</span>)
              <v-tooltip location="top" max-width="300" style="" color="red">
                <template v-slot:activator="{ props }">
                  <v-icon icon="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" v-bind="props"> </v-icon>
                </template>
                <span> Thời gian kết thúc.</span>
              </v-tooltip>
            </v-list-item>
            <VueDatePicker
              style="margin-right: 20px;"
              v-model="form.end_time"
              :enable-time-picker="true"
            />
          </v-col> -->
        </v-row>
      </v-row>

      <v-row class="d-flex align-center justify-center">
        <v-card-text :style="`color:${color}`"> {{ notifi_message }}</v-card-text>
        <v-card-actions>
          <v-btn v-if="form._id" color="green" @click="onUpdate(form)">Cập nhật</v-btn>
          <v-btn v-else color="green" @click="onAddTang_tuong_tac(form)"
            >Thêm cài đặt</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn color="red" @click="dialog_add = false">Hủy bỏ</v-btn>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
// .wrapper {
//   position: relative;
//   margin-top: 150px;
//   text-align: center;
// }
</style>
